<template>
  <div class="User_I">
    <div class="headNavs">
      <div class="headTilte">用户列表</div>
      <div class="headDetails">显示平台所有的用户信息</div>
    </div>

    <div class="capitalNav">
      <div style="display:flex;justify-content: space-between;">
        <div class="NavLi">
          <div class="NavLiName">站点：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.site_id" placeholder="全部" style="width:220px">
              <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">昵称：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.nickname" placeholder="请填写昵称" ></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">手机号：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.mobile" placeholder="请填写手机号" ></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">等级：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.level" placeholder="全部">
              <el-option v-for="(item,key) in LevelList" :key="key" :label="item" :value="key"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi" v-for="obj in 4" :key="obj"></div>
      </div>
      <div style="margin-top:20px">
        <div class="NavLi">
          <div class="NavLiName">注册时间</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择时间" :clearable="false" ></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择时间" :clearable="false" ></el-date-picker>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiDelte" @click="DelMore()"><i class="el-icon-delete"></i>批量删除</div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column align="center" prop="site.name" label="站点" width="140"></el-table-column>
        <el-table-column align="center" prop="nickname" label="昵称" width="120"></el-table-column>
        <el-table-column align="center" label="头像" width="140">
          <template #default="scope">
            <el-image class="Toper"
                      :src="scope.row.full_avatar" alt=""
                      :preview-src-list="[scope.row.full_avatar]"
                      :initial-index="0"
                      :hide-on-click-modal=true
                      fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="mobile" label="手机" width="120"></el-table-column>
        <el-table-column align="center" prop="identity" label="角色"></el-table-column>
        <el-table-column align="center" prop="level" label="等级"></el-table-column>
        <el-table-column align="center" prop="vip_endtime_text" label="有效时间" :formatter="showTime"></el-table-column>
        <el-table-column align="center" prop="jointime_text" label="注册时间" width="200"></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template #default="scope">
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "index",
  data() {
    return {
      //搜索参数
      search: {
        site_id: "",
        nickname: "",
        mobile: "",
        level: "",
        time: {
          min: "",
          max: "",
        },
        keyword: "",
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
    }
  },
  mounted() {
    var that = this
    that.loadTable();

    axios.get('/admin/user/user/statisticsColumns', {   // 设置选项
    }).then(function (res) {
      if (res.data.code == 0) {
        alert('请求失败');
      } else {
        that.LevelList = res.data.data.level;
      }
    })

    axios.get('/admin/ajax/siteList', {   // 站点列表
    }).then(function (res) {
      if (res.data.code == 0) {
        alert('请求失败');
      } else {
        that.SiteList = res.data.data
      }
    })
  },
  methods: {

    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/user/user/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
        
      })
    },

    BtnSearch(e) {
      var that = this
      if (that.search.time.min != '') {
        that.search.time.min = that.formatDate(that.search.time.min);
      }
      if (that.search.time.max != '') {
        that.search.time.max = that.formatDate(that.search.time.max);
      }
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          site_id: "",
          nickname: "",
          mobile: "",
          level: "",
          time: {
            min: "",
            max: "",
          },
          keyword: "",
        };
      }
      that.loadTable();
    },
    HandleDel(e, data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.delTable(arr);
      })
    },
    DelMore() {
      var that = this
      var arr = that.multipleSelection_id
      if (that.multipleSelection_id == null) {      //批量删除
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
        }).then(() => {
          that.delTable(arr);
        })
      }
    },
    delTable(arr) {
      var that = this
      axios({
        method: 'delete',
        url: '/admin/user/user/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    handleSelect(e) {          // 勾选
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    showTime(row, column) {
      if (row.vip_endtime_text == '' || row.vip_endtime_text == 0) {
        return '-'
      } else {
        return row.vip_endtime_text;
      }
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      return y + "-" + MM + "-" + d
    },
  }
}
</script>

<style scoped>     
.User_I /deep/ .el-input__inner{
  height: 32px;
  line-height: 32px;
} 
</style>
